import { css } from '@emotion/react'
import { TableCell, Typography } from '@mui/material'
import { formatDateTime } from '../../../lib/format'

export type TableCellDateProps = {
  dateTime: Date | number | null | undefined
}

export function TableCellDateTime({ dateTime }: TableCellDateProps): JSX.Element {
  return (
    <TableCell
      css={css`
        margin-top: 0;
      `}
    >
      <Typography>{formatDateTime(dateTime)}</Typography>
    </TableCell>
  )
}
